<script setup>
import { ref, onMounted, computed } from 'vue';
import { Vueginate } from 'vueginate';
import 'vueginate/css/vueginate.css';
import { useI18n } from 'vue-i18n';
import Multiselect from '@vueform/multiselect';
import '@vueform/multiselect/themes/default.css'
import TransactionsApi from '@/api/TransactionsApi';
import ListTransactions from './ListTransactions.vue';
import { url } from '@/main';
import axios from 'axios';

// 'INVESTMENT' => 0, // фикс one и фикс голд
// 'PARTNER' => 1, // партнерские доллары
// 'DEXNET' => 2, // партнерские доллары за dexnet 
// 'DEXNET_DEXNET' => 3, // партнерские dexnet токены
// 'TWO' => 4, // fix two
// 'DEXNET_MATCHING' => 5, // dexnet токены за матчинг

const emit = defineEmits(['page', 'per-page'])

const transactionsApi = new TransactionsApi()

onMounted(() => {
  if (props.single) {
    getTransaction()
  }else {
    getTransactions()
  }
})

const { t } = useI18n()

const props = defineProps({
  headers: {
    type: Array,
    required: false,
    default: () => [['type', 'amount', 'tokens', 'status'], ['type', 'status']]
  },
  typeFilter: {
    type: Array,
    required: false,
    default: () => []
  },
  billFilter: {
    type: Array,
    required: false,
    default: () => []
  },
  single: {
    type: [Number, Boolean],
    required: false,
    default: () => false
  }
})


const tableNavigationData = ref({
  total: 0,
  current: 1,
  perPage: 10,
  toShow: 1,
});

const transactionsNumberToShow = ref(10)

const pageChange = (value) => {
  tableNavigationData.value.current = value
  getTransactions()
}

const changePerPage = (value) => {
  tableNavigationData.value.perPage = value
  getTransactions()
}

const transactions = ref([])

const getTransactions = () => {
  transactionsApi.getTransactions(tableNavigationData.value.current, tableNavigationData.value.perPage, props.typeFilter, props.billFilter)
    .then(({ data }) => {
      const res = data.data
      transactions.value = res.data
      tableNavigationData.value.total = res.total
      tableNavigationData.value.perPage = res.per_page
      tableNavigationData.value.current = res.current_page
    })
}

const getTransaction = () => {
  transactionsApi.getTransaction(props.single).then(({data}) => {
    const res = data.data
    transactions.value = [res]
  })
}

// axios.get(`http://127.0.0.1:3333/api/payments/transaction?page=${1}&count=${10}`, { headers: { Authorization: `Bearer ${localStorage.getItem('tokenAuth')}` }, withCredentials: true }).then(({data}) => {
//     transactions.value = data.data.data
//     // pageNav.value = {
//     //     currentPage: data.data.current_page,
//     //     lastPage: data.data.last_page,
//     // }
//     console.log("transactions", transactions.value)
//   }).catch((err) => {
//     console.log(err)
//     if (err.response.status === 401) {
//       localStorage.removeItem('tokenAuth')
//       document.location.href = document.location.origin
//     }
//   })


</script>


<template>

  <div class="transactions">
    <div v-if="!props.single" class="transactions__pagination">
      <p style="min-width: 100px;">
        <Multiselect @change="changePerPage" v-model="transactionsNumberToShow" :can-clear="false" :label="t('show')"
          :options="[10, 25, 50, 100]" />
      </p>
      <vueginate :total-items="tableNavigationData.total" :current-page="tableNavigationData.current"
        :items-per-page="tableNavigationData.perPage" :pages-to-show="1"
        :custom-styles="{ container: ['justify-center'] }" @page-change="pageChange" visible-always />
    </div>
    <ListTransactions @delete-transaction="getTransactions" :transactions="transactions" :headers="headers" />
    <div v-if="!props.single" class="transactions__pagination mt-3">
      <p style="min-width: 100px;">
        <Multiselect @change="changePerPage" v-model="transactionsNumberToShow" :can-clear="false" :label="t('show')"
          :options="[10, 25, 50, 100]" />
      </p>
      <vueginate :total-items="tableNavigationData.total" :current-page="tableNavigationData.current"
        :items-per-page="tableNavigationData.perPage" :pages-to-show="1"
        :custom-styles="{ container: ['justify-center'] }" @page-change="pageChange" visible-always />
    </div>
  </div>
</template>

<style lang="scss">
:root {
  --ms-bg: transparent;
  --ms-option-color-pointed: #fff;
  --ms-option-bg-pointed: transparent;
  --ms-dropdown-bg: rgb(23, 23, 23);
  --ms-group-label-bg-selected: rgb(243 65 40);
  --ms-option-bg-selected-pointed: rgb(243 65 40);
  --ms-option-bg-selected: rgb(243 65 40);
}

.transactions {

  max-width: 100%;

  .vg-active {
    color: #fff;
    border-color: #fff;
    background-color: rgb(243 65 40);
  }

  .vg-page {
    &:hover {
      background-color: rgba(243 65 40 / .7);
      color: rgba(255, 255, 255, .7);
    }
  }

  .vg-arrow:not(.vg-disabled):hover {
    background-color: rgba(243 65 40 / .8);
    color: rgba(255, 255, 255, 1);
  }
}

.transactions__pagination {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.transactions__header {
  display: flex;
  width: 100%;

  margin-bottom: 5px;

  span {
    flex: 2;
    text-align: center;

    &:first-child {
      text-align: left;
    }
  }
}
</style>