<template>
  <svg viewBox="0 40 64 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="32" cy="81" r="9" fill="#00F663" />
    <circle cx="32" cy="57" r="9" fill="#00F663" />
    <circle cx="32" cy="33" r="9" fill="#00F663" />
    <circle cx="32" cy="9" r="9" fill="#00F663" />
    <circle cx="55" cy="57" r="9" fill="#00F663" />
    <circle cx="9" cy="57" r="9" fill="#00F663" />
  </svg>
</template>
