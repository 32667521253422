export default {
  en: {
    withdrawDexnetForm: {
      'transferTokens': 'Transfer DEXNET tokens to DexNet wallet',
      'note': `
      <p>For withdrawals, use only your wallet address that is linked to your DexNet account!</p>
      <p>(on dexnet.one website in the 'Transactions' section - 'Top Up')</p>
      <p>Withdrawal fee 2%</p>
      `,
      'placeholder': 'wallet address'
    },
    dexnet_account_binding: 'To synchronize with the DEXNET personal account, enter your email address specified during registration of the DEXNET account.',
    enter_the_code: 'Enter the code sent to the specified email address.',
    dexsafe_wallet: `Enter your referral code in the DexSAFE app<br>
    to save your affiliate structure in your wallet`,
    ourBalance: 'Our balance:',
    dexnetBuy: 'Buy',
    dexnetYes: 'Yes',
    dexnetNo: 'No',
    dexnetInfo:
      "Decentralized, Autonomous, Secure Next Generation Internet with SPACEX's SWARM Low Earth Orbit Satellite Network",
    dexnetCapInternet: 'True DEX internet',
    dexnetInfoInternet: `Communication between API KEY devices is provided by two
    independent channels for the transmission of encryption keys and
    other information requiring increased security.`,
    dexnetCapChannel: `Own high-frequency channel`,
    dexnetInfoChannel: `Autonomous communication channel at a frequency of 433 MHz,
    thanks to which API KEY devices detect each other within a
    radius of up to 10 km. (without putting a signal into orbit)
    and form a stable and secure communication channel by
    overlapping each other with a terrestrial radio signal
    according to the honeycomb principle.`,
    dexnetCapLow: 'Low orbit satellite channel',
    dexnetInfoLow: `The second communication link is provided by direct connection
    of the API KEY devices to Space X's Swarm satellite system.
    The API KEY connects via a compact antenna to the nearest
    Swarm low-orbit satellite for two-way communication. The Space
    X Swarm satellites form a stable link with the API KEY,
    covering the planet 100%.`,
    dexnetInfoParallel: `In parallel, the nodes use the public Internet to transmit
    large volume encrypted data packets.`,
    dexnetCapList: `The whole world in a small device`,
    dexnetListItem1: 'СPU: 4-core Rockship RK3566',
    dexnetListItem2: 'Info: Full Color IPS LCD',
    dexnetListItem3: 'Ethernet: RJ45, Wi-Fi',
    dexnetListItem4: 'Satellite system: SWARM by SpaceX',
    dexnetListItem5: 'Cryptocurrency mining with high performance',
    dexnetListItem6: 'Info: Full Color IPS LCD Space X SWARM',
    dexnetListItem7: 'Dex Wan: own high-frequency terrestrial channel 915 MHz, 5.5 km',
    dexnetListItemGSM: 'c e-sim DEX Mobile',
    dexnetNodeInfo: `Is a physical device with a high-performance processor, large SSD
    memory, a unique NFT authenticator and its own independent wireless
    communication channels`,
    dexnetCommunicationChannels: `communication<br />
    channels`,
    dexnetSSDDrive: `SSD-<br />
    drive`,
    buyDevice: 'Buy device',
    instructionsFixOneTitle: 'Purchase device for FixOne token',
    instructionsFixOne1:
      'If you want to purchase a device for {price} FixOne token, click "Buy" and confirm the transaction.',
    instructionsFixOne2: `The promocode will be sent to your email. Activate this promocode by following the  <a class="text-[#00FFF0]" href="https://dexnet.one/">link</a> and receive the tracking number for delivery.`,
    instructionsFixOneConfirm:
      'Are you sure you want to purchase DEXNET API ?',
    instructionsPartnershipTitle: 'Purchase device for USDT Partnership',
    instructionsPartnership1:
      'If you want to purchase a device for 1500 USDT Partnership, click "Buy" and confirm the transaction.',
    instructionsPartnership2: `The promocode will be sent to your email. Activate this promocode by following the  <a class="text-[#00FFF0]" href="https://dexnet.one/">link</a> and receive the tracking number for delivery.`,
    instructionsUsdtTitle: 'How to purchase a device with {token}?',
    instructionsNumber: 'Set quantity (max 50 for transaction)',
    instructionsUsdt1: 'Step 1: Access your {token} wallet.',
    instructionsUsdt2: 'Step 2: Send {token} to the following address:',
    instructionsUsdt3:
      'Step 3: After you send the {token}, enter the transaction HASH or provide a screenshot of the transaction from your wallet, then click the "Confirm" button.',
    instructionsUsdt4:
      'Step 4: The promocode will be sent to your email. Activate this promocode by following the  <a class="text-[#00FFF0]" href="https://dexnet.one/">link</a> and receive the tracking number for delivery.',
    operationInProgress: 'Operation in progress.',
    operationInProgressInfo:
      'The promocode will be sent to your email. Activate this promocode by following the  <a class="text-[#00FFF0]" href="https://dexnet.one/">link</a> and receive the tracking number for delivery.'
  },
  ru: {
    withdrawDexnetForm: {
      'transferTokens': 'Вывести DEXNET токены на кошелек DexNet',
      'note': `
      <p>Для вывода средств используйте только адрес вашего кошелька, который привязан к вашей учетной записи DexNet!</p>
      (на веб-сайте dexnet.one в разделе "Транзакции" - "Пополнить счет")
      <p>Комиссия за вывод средств 2%</p>
      `,
      'placeholder': 'wallet address'
    },
    dexnet_account_binding: 'Для синхронизации с личным кабинетом DEXNET, введите вашу электронную почту, указанную при регистрации кабинета DEXNET.',
    enter_the_code: 'Введите код отправленный на указанную электронную почту.',
    dexsafe_wallet: `Введите Ваш реферальный код в приложении DexSAFE, <br>
    для сохранения вашей  партнёрской структуры в кошельке`,
    ourBalance: 'Баланс:',
    dexnetBuy: 'Купить',
    dexnetYes: 'Да',
    dexnetNo: 'Нет',
    dexnetInfo:
      "Decentralized, Autonomous, Secure Next Generation Internet with SPACEX's SWARM Low Earth Orbit Satellite Network",
    dexnetCapInternet: 'True DEX internet',
    dexnetInfoInternet: `Communication between API KEY devices is provided by two
    independent channels for the transmission of encryption keys and
    other information requiring increased security.`,
    dexnetCapChannel: `Own high-frequency channel`,
    dexnetInfoChannel: `Autonomous communication channel at a frequency of 433 MHz,
    thanks to which API KEY devices detect each other within a
    radius of up to 10 km. (without putting a signal into orbit)
    and form a stable and secure communication channel by
    overlapping each other with a terrestrial radio signal
    according to the honeycomb principle.`,
    dexnetCapLow: 'Low orbit satellite channel',
    dexnetInfoLow: `The second communication link is provided by direct connection
    of the API KEY devices to Space X's Swarm satellite system.
    The API KEY connects via a compact antenna to the nearest
    Swarm low-orbit satellite for two-way communication. The Space
    X Swarm satellites form a stable link with the API KEY,
    covering the planet 100%.`,
    dexnetInfoParallel: `In parallel, the nodes use the public Internet to transmit
    large volume encrypted data packets.`,
    dexnetCapParams: `The whole world in a small device`,
    dexnetCapList: `The whole world in a small device`,
    dexnetListItem1: 'СPU: 4-core Rockship RK3566',
    dexnetListItem2: 'Info: Full Color IPS LCD',
    dexnetListItem3: 'Ethernet: RJ45, Wi-Fi',
    dexnetListItem4: 'Satellite system: SWARM by SpaceX',
    dexnetListItem5: 'Cryptocurrency mining with high performance',
    dexnetListItem6: 'Info: Full Color IPS LCD Space X SWARM',
    dexnetListItem7: 'Dex Wan: own high-frequency terrestrial channel 915 MHz, 5.5 km',
    dexnetListItemGSM: 'c e-sim DEX Mobile',
    dexnetNodeInfo: `Is a physical device with a high-performance processor, large SSD
    memory, a unique NFT authenticator and its own independent wireless
    communication channels`,
    dexnetCommunicationChannels: `communication<br />
    channels`,
    dexnetSSDDrive: `SSD-<br />
    drive`,
    instructionsFixOneTitle: 'Покупка устройства за FixOne token',
    instructionsFixOne1:
      'Если Вы хотите приобрести устройство за {price} FixOne token нажмите купить и подтвердите операцию.',
    instructionsFixOne2: `Промокод поступит к Вам на email. Данный промокод активируйте по <a class="text-[#00FFF0]" href="https://dexnet.one/">ссылке</a> и получите трек номер отслеживания доставки.`,
    instructionsFixOneConfirm: 'Вы уверены что хотите приобрестиDEXNET API ?',
    instructionsPartnershipTitle: 'Покупка устройства за USDT Partnership',
    instructionsPartnership1:
      'Если Вы хотите приобрести устройство за 1500 USDT Partnership нажмите купить и подтвердите операцию.',
    instructionsPartnership2: `Ваучер поступит к Вам на email.  Данный ваучер активируйте по <a class="text-[#00FFF0]" href="https://dexnet.one/">ссылке</a> и получите трек номер отслеживания доставки.`,
    instructionsUsdtTitle: 'Как купить устройство за {token}?',
    instructionsNumber: 'Укажите количество (максимум 50 за транзакцию)',
    instructionsUsdt1: '1 Шаг: Зайдите в Ваш кошелек {token}.',
    instructionsUsdt2: '2 Шаг: Отправьте {token} на адрес:',
    instructionsUsdt3:
      '3 Шаг: После того, как вы отправите {token}, введите HASH транзакции либо приложите скриншот Транзакции из вашего кошелька и нажмите кнопку Подтвердить.',
    instructionsUsdt4:
      '4 Шаг: Промокод поступит к Вам на email. Данный промокод активируйте по <a class="text-[#00FFF0]" href="https://dexnet.one/">ссылке</a> и получите трек номер отслеживания доставки.',
      operationInProgressInfo: 'Промокод поступит к Вам на email. Данный промокод активируйте по <a class="text-[#00FFF0]" href="https://dexnet.one/">ссылке</a> и получите трек номер отслеживания доставки.'
  },
  es: {
    withdrawDexnetForm: {
      'transferTokens': 'Retirar tokens DEXNET a la billetera DexNet',
      'note': `
      <p>For withdrawals, use only your wallet address that is linked to your DexNet account!</p>
      <p>(on dexnet.one website in the 'Transactions' section - 'Top Up')</p>
      <p>Withdrawal fee 2%</p>
      `,
      'placeholder': 'wallet address'
    },
    dexnet_account_binding: 'Para sincronizar con la cuenta personal de DEXNET, introduzca la dirección de correo electrónico especificada durante el registro de la cuenta de DEXNET.',
    enter_the_code: 'Ingrese el código enviado a la dirección de correo electrónico especificada.',
    dexsafe_wallet: `Ingrese su código de referencia en la aplicación DexSAFE<br> para guardar su estructura de afiliado en su billetera`,
    ourBalance: 'Nuestro equilibrio:',
    dexnetBuy: 'Сomprar',
    dexnetYes: 'Sí',
    dexnetNo: 'No hay',
    dexnetInfo:
      "Decentralized, Autonomous, Secure Next Generation Internet with SPACEX's SWARM Low Earth Orbit Satellite Network",
    dexnetCapInternet: 'True DEX internet',
    dexnetInfoInternet: `Communication between API KEY devices is provided by two
    independent channels for the transmission of encryption keys and
    other information requiring increased security.`,
    dexnetCapChannel: `Own high-frequency channel`,
    dexnetInfoChannel: `Autonomous communication channel at a frequency of 433 MHz,
    thanks to which API KEY devices detect each other within a
    radius of up to 10 km. (without putting a signal into orbit)
    and form a stable and secure communication channel by
    overlapping each other with a terrestrial radio signal
    according to the honeycomb principle.`,
    dexnetCapLow: 'Low orbit satellite channel',
    dexnetInfoLow: `The second communication link is provided by direct connection
    of the API KEY devices to Space X's Swarm satellite system.
    The API KEY connects via a compact antenna to the nearest
    Swarm low-orbit satellite for two-way communication. The Space
    X Swarm satellites form a stable link with the API KEY,
    covering the planet 100%.`,
    dexnetInfoParallel: `In parallel, the nodes use the public Internet to transmit
    large volume encrypted data packets.`,
    dexnetCapParams: `The whole world in a small device`,
    dexnetCapList: `The whole world in a small device`,
    dexnetListItem1: 'СPU: 4-core Rockship RK3566',
    dexnetListItem2: 'Info: Full Color IPS LCD',
    dexnetListItem3: 'Ethernet: RJ45, Wi-Fi',
    dexnetListItem4: 'Satellite system: SWARM by SpaceX',
    dexnetListItem5: 'Cryptocurrency mining with high performance',
    dexnetListItem6: 'Info: Full Color IPS LCD Space X SWARM',
    dexnetListItem7: 'Dex Wan: own high-frequency terrestrial channel 915 MHz, 5.5 km',
    dexnetListItemGSM: 'c e-sim DEX Mobile',
    dexnetNodeInfo: `Is a physical device with a high-performance processor, large SSD
    memory, a unique NFT authenticator and its own independent wireless
    communication channels`,
    dexnetCommunicationChannels: `communication<br />
    channels`,
    dexnetSSDDrive: `SSD-<br />
    drive`,
    instructionsFixOneTitle: 'Compra de dispositivo con token FixOne',
    instructionsFixOne1:
      'Si desea adquirir un dispositivo por {price} token FixOne, haga clic en "Comprar" y confirme la operación.',
    instructionsFixOne2: `El Cupón le llegará por correo electrónico. Activa este cupón siguiendo el <a class="text-[#00FFF0]" href="https://dexnet.one/">enlace</a> y obtén el número de seguimiento para la entrega.`,
    instructionsFixOneConfirm:
      '¿Estás seguro de que quieres comprarDEXNET API?',
    instructionsPartnershipTitle: 'Compra de dispositivo con USDT Partnership',
    instructionsPartnership1:
      'Si desea adquirir un dispositivo por 1500 USDT Partnership, haga clic en "Comprar" y confirme la operación.',
    instructionsPartnership2: `El Cupón le llegará por correo electrónico. Active este cupón siguiendo el <a class="text-[#00FFF0]" href="https://dexnet.one/">enlace</a> y obtenga el número de seguimiento para la entrega.`,
    instructionsUsdtTitle: '¿Cómo comprar un dispositivo con {token}?',
    instructionsNumber: 'Set quantity (max 50 for transaction)',
    instructionsUsdt1: 'Paso 1: Accede a tu billetera de {token}.',
    instructionsUsdt2: 'Paso 2: Envía {token} a la siguiente dirección:',
    instructionsUsdt3:
      'Paso 3: Después de enviar los {token}, ingresa el HASH de la transacción o adjunta una captura de pantalla de la transacción desde tu billetera y haz clic en el botón "Confirmar"',
    instructionsUsdt4:
      'Paso 4: El Cupón le llegará por correo electrónico. Activa este cupón siguiendo el <a class="text-[#00FFF0]" href="https://dexnet.one/">enlace</a> y obtén el número de seguimiento para la entrega.',
    operationInProgress: 'Operación en curso.',
    operationInProgressInfo:
      'El Cupón le llegará por correo electrónico. Activa este cupón siguiendo el <a class="text-[#00FFF0]" href="https://dexnet.one/">enlace</a> y obtén el número de seguimiento para la entrega.'
  }
}
