<script setup="">
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import DepositIcon from './icons/DepositIcon.vue';
import ExchangeIcon from './icons/ExchangeIcon.vue';
import WithdrawIcon from './icons/WithdrawIcon.vue';
import FixOneIcon from './icons/FixOneIcon.vue';
import FixTwoIcon from './icons/FixTwoIcon.vue';
import DexIcon from './icons/DexIcon.vue';

const { t } = useI18n()

const store = useStore()

const props = defineProps({
  transaction: {
    type: Object,
    required: true
  }
})

const date = computed(() => {
  const date = new Date(props.transaction.created_at)
  const [day, month, year] = [date.getDate(), date.getMonth(), date.getFullYear()]
  return `${day}.${month + 1}.${year}`
})

const typeName = ref({
  11: 'depositTransaction',
  0: 'depositTransaction',
  1: 'withdrawType',
  2: 'exchangeType',
  3: 'directBonusType',
  4: 'globalPoolGoldType',
  5: 'globalPoolDiamondType',
  6: 'weeklyBonusType',
  7: 'depositFixBonusType',
  8: 'depositUsdBonusType',
  9: 'withdrawFixBonusType',
  10: 'withdrawUsdBonusType',
  12: 'autoExchangeType',
  13: 'withdrawType',
  14: 'exchangeType',
  15: 'depositCryptohouseType',
  16: 'exchangeFixOneType',
  17: 'DEXNET API KEY',
  18: 'DEXNET API KEY USDT',
  19: 'DEXNET API KEY Partner',
  20: 'DEXNET Direct Bonus',
  21: 'DEXNET TEAM BONUS',
  22: 'DEXNET MARATHON BONUS',
  23: 'DEX POOL BONUS',
  24: 'DEXNET DEPOSIT USDT',
  25: 'DEXNET VALIDATOR KEY',
  26: 'VALIDATOR DIRECT BONUS USDT',
  27: 'VALIDATOR DIRECT BONUS DEXNET',
  28: 'depositTransaction',
  29: 'withdrawType',
  30: 'WITHDRAW DEXNET',
  31: 'MATCHING BONUS',
  32: 'DIRECT BONUS FIX TWO ',
  36: 'WITHDRAW DEXNET MATCHING',
  37: 'WITHDRAW_DEX_ID'
})



const billTypes = {
  0: 'FIX',
  1: 'Partner',
  2: 'DEXNET',
  3: 'DEXNET',
  4: 'FIX TWO',
  5: 'DEXNET'
}

const depositTransactions = [0, 3, 4, 5, 6, 7, 8, 11, 15, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 31, 32]
const withdrawTransactions = [1, 9, 10, 13, 29, 30, 36, 37]
const exchangeTransactions = [2, 12, 14, 16]



const fixOneTransactions = [0, 1, 16]
const fixGoldTransactions = [11, 13, 14]
const fixTwoTransactions = [28, 29]

const transactionsTypeTokens = computed(() => {
  return store.getters.getTransactionsTypeTokens
})


const iconTransaction = computed(() => {

  let icon

  switch (true) {
    case depositTransactions.includes(props.transaction.type):
      icon = DepositIcon
      break
    case withdrawTransactions.includes(props.transaction.type):
      icon = WithdrawIcon
      break
    case exchangeTransactions.includes(props.transaction.type):
      icon = ExchangeIcon
      break
    case props.transaction.bill_type === 0 || props.transaction.bill_type === 1:
      icon = FixOneIcon
      break
    case props.transaction.bill_type === 2 ||
      props.transaction.bill_type === 3 ||
      props.transaction.bill_type === 5 :
      icon = DexIcon
      break
    case props.transaction.bill_type === 0:
      icon = FixOneIcon
      break
    default: 
      icon = FixOneIcon
  }

  return icon
}
)

const cellNameToken = computed(() => {
  let tokenName = ''

  switch (true) {
    case transactionsTypeTokens.value.fixOneTransactions.includes(props.transaction.type):
      tokenName = 'FIX ONE'
      break
    case transactionsTypeTokens.value.fixGoldTransactions.includes(props.transaction.type):
      tokenName = 'FIX GOLD'
      break
    case transactionsTypeTokens.value.fixTwoTransactions.includes(props.transaction.type):
      tokenName = 'FIX TWO'
      break
  }

  return tokenName
})

const typeCellName = computed(() => {
  let name 

  switch(true) {
    case props.transaction.bill_type === 1 && withdrawTransactions.includes(props.transaction.type): 
      name = t(typeName.value[props.transaction.type] ?? billTypes[props.transaction.bill_type]) + ' ' + t('partner')
      break
    default:
      name = t(typeName.value[props.transaction.type] ?? billTypes[props.transaction.bill_type]) + ' ' + cellNameToken.value 
  }

  return name
})

</script>


<template>
  <div class="typeCell">
    <div class="typeCell__icon">
      <component :is="iconTransaction" />
    </div>
    <div class="typeCell__data">
      <span class="typeCell__name" :class="{ 'typeCell__name--dexnet': props.transaction.bill_type === 3 }">
        <!-- {{ t(typeName[props.transaction.type] ?? billTypes[props.transaction.bill_type]) ?? 'transaction' }}
        {{ cellNameToken }} -->
        {{ typeCellName }}
      </span>
      <span class="typeCell__date">{{ date }}</span>
    </div>
  </div>

</template>

<style lang="scss">
div.typeCell {
  display: flex;
  align-items: center;
  padding-left: 3rem;
  text-align: left;
  padding-right: 10px;
}

.typeCell__icon {
  background: #3B3B3B;
  padding: .5rem 1rem;
  border-radius: 8px;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;

  svg {
    width: 1rem;
    height: 40px;
  }
}

.typeCell__data {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.typeCell__name {
  &--dexnet {
    color: #00FFF0;
  }
}
</style>