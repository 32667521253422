<script setup="">
import { onMounted } from 'vue';
import TableTransactions from '../TableTransactions.vue';

const props = defineProps({
  transactionId: {
    type: [Number, String],
    required: false
  }
})

onMounted(() => {
  console.log('id' ,  props.transactionId)
})

</script>


<template>
  <TableTransactions :single="props.transactionId"/>
</template>