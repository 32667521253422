<script setup>
import {ref, computed} from 'vue'
import { useI18n } from 'vue-i18n';

const { t } = useI18n()
/* eslint-disable */
const statusName = ref({
  '45': 'waitingForActivation',
  '10': 'pending',
  '5': 'waitVerification',
  '1': 'pending',
  '0': 'created',
  '100': 'completed',
  '-20': 'declinedByUser',
  '-30': 'declined'
})


const props = defineProps({
  transaction: {
    type: Object,
    required: true
  }
})

const status = computed(() => (props.transaction.status))

</script>

<template>

<div class="statusCell">
  <div
  :class="{
    'created': status == '0',
    'pending': status == '1' || status == '10',
    'awaiting_verification': status == '5',
    'awaiting_activation': status == '45',
    'completed': status == '100',
    'declined_user': status == '-20',
    'declined': status == '-30'
  }"
  class="statusCell__status">
    {{ t(statusName[props.transaction.status]) }}
  </div>
</div>

</template>

<style lang="scss">

.statusCell{
  display: flex;
  justify-content: center;
  align-items: center;
}

.statusCell__status{
  background: rgb(156 163 175);
  padding: .5rem .5rem;
  border-radius: 8px;
  width: 100%;
  max-width: 150px;
  text-align: center;

  &.pending{
    background-color: rgb(251 146 60);
  }

  &.await_verification{
    background-color: rgb(251 146 60);
  }

  &.await_activation{
    background-color: rgb(251 146 60);
  }

  &.completed{
    background-color: rgb(136 190 113);
  }

  &.declined{
    background-color: rgb(246 110 110);
  }
}


</style>