<script setup="">
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore()

const props = defineProps({
  transaction: {
    type: Object,
    required: true
  }
})

const tokensValue = computed(() => {
  return Number(props.tokens).toFixed(4)
})


const transactionTokens = computed(() => {
  return !!props.transaction.amount_in_fix ? props.transaction.amount_in_fix : props.transaction.data?.amount_in_token
})

const tokensName = computed(() => {
  let tokenName = ''

  switch(true) {
    case transactionsTypeTokens.value.fixOneTransactions.includes(props.transaction.type):
      tokenName = 'FIX ONE'
      break
    case transactionsTypeTokens.value.fixGoldTransactions.includes(props.transaction.type):
      tokenName = 'FIX GOLD'
      break
    case transactionsTypeTokens.value.fixTwoTransactions.includes(props.transaction.type):
      tokenName = 'FIX TWO'
      break
  }

  return tokenName 
})

const transactionsTypeTokens = computed(() => store.getters.getTransactionsTypeTokens)

</script>


<template>

<div class="tokensCell">
  <div v-if="transactionTokens" class="tokensCell__value">
    <span >{{ Number(transactionTokens).toFixed(4) }}</span> 
    <span>{{ tokensName }}</span>
  </div>
</div>

</template>

<style lang="scss">

.tokensCell__value{
  display: flex;
  flex-direction: column;
}
</style>