<script setup="">
import { computed } from 'vue';


const props = defineProps({
  transaction: {
    type: Object,
    required: false
  },
  title: {
    type: String,
    required: true
  }
})

const amountInTokens = computed(() => {
  return props.transaction.amount_in_fix ?? props.transaction.data?.amount_in_token 
})

const isHasValue = computed(() => {
  if (props.title === 'tokens' && !amountInTokens.value) return false
  return true
})
</script>

<template>

<div v-if="isHasValue" class="field">
  <span class="transaction__expand_field">
    <span style="padding-right: 5px">{{ props.title }} :</span> 
    <slot></slot>
  </span>
</div>

</template>