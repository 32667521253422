<template>
  <svg viewBox="0 0 78 92" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6.3" cy="6.3" r="6.3" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 12.5991 75.7002)"
      fill="#00AF47" />
    <circle cx="6.3" cy="6.3" r="6.3" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 29.3989 75.7002)"
      fill="#00AF47" />
    <circle cx="6.3" cy="6.3" r="6.3" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 46.1985 75.7002)"
      fill="#00AF47" />
    <circle cx="6.3" cy="6.3" r="6.3" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 63 75.7002)" fill="#00AF47" />
    <circle cx="6.3" cy="6.3" r="6.3" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 29.3989 59.5996)"
      fill="#00AF47" />
    <circle cx="6.3" cy="6.3" r="6.3" transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 29.3989 91.8008)"
      fill="#00AF47" />
    <circle cx="6.32798" cy="6.32798" r="6.32798" transform="matrix(-4.37114e-08 1 1 4.37114e-08 64.4836 16.1709)"
      fill="#FFB96A" />
    <circle cx="6.32798" cy="6.32798" r="6.32798" transform="matrix(-4.37114e-08 1 1 4.37114e-08 47.6102 16.1709)"
      fill="#FFB96A" />
    <circle cx="6.32798" cy="6.32798" r="6.32798" transform="matrix(-4.37114e-08 1 1 4.37114e-08 30.7349 16.1709)"
      fill="#FFB96A" />
    <circle cx="6.32798" cy="6.32798" r="6.32798" transform="matrix(-4.37114e-08 1 1 4.37114e-08 13.8594 16.1709)"
      fill="#FFB96A" />
    <circle cx="6.32798" cy="6.32798" r="6.32798" transform="matrix(-4.37114e-08 1 1 4.37114e-08 47.6102 32.3442)"
      fill="#FFB96A" />
    <circle cx="6.32798" cy="6.32798" r="6.32798" transform="matrix(-4.37114e-08 1 1 4.37114e-08 47.6102 0)"
      fill="#FFB96A" />
  </svg>
</template>