import {ref} from 'vue'
import Api from "./Api.";

class TransactionsApi extends Api {

  page = 1
  perPage = 10
  current = 1
  total = 0
  filter = ''

  navigation = {
    page: this.page,
    perPage: this.perPage,
    current: this.current,
    total: this.total
  }

  constructor() {
    super()
  }

  async getTransactions(page = 1, count = 10, typeFilter = [], billFilter = []) {
    this.page = page
    this.perPage = count
    const filterUrl = this.makeFilter(typeFilter, billFilter)
    return this.api.get(`/api/payments/transaction?page=${page}&count=${count}${filterUrl}`)
      .then(response => {
        this.total = response.data.data.total
        console.log('total response', response.data.data.total)
        console.log('total this', this.total)
        return response
      })
  }

  async getTransaction(id = 0) {
    return this.api.get(`/api/payments/transaction/${id}`)
  }

  makeFilter(transactionsTypes = [], billTypes = []) {
    let filter = ''
    transactionsTypes.forEach(type => {
      filter += `&type[]=${type}`
    })
    billTypes.forEach(type => {
      filter += `&bill_type[]=${type}`
    })
    return filter
  }

  async deleteTransaction(type, id) {
    return this.api.delete(`/api/payments/${type}/${id}`)
  }
}

export default TransactionsApi