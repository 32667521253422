import axios from "axios";
import { url } from "@/main";

class Api {

  api

  constructor() {
    this.api = axios.create({
        baseURL: url,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('tokenAuth')}`,
          'Content-Type': 'application/json' 
        },
        withCredentials: true
    })
  }
}

export default Api