<template>
  <svg version="1.1" id="Слой_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
    y="0px" viewBox="0 0 212 200.7" style="enable-background:new 0 0 212 200.7;" xml:space="preserve">

    <g id="Black">
      <g id="_3">
        <path class="st0"
          d="M27.6,200.7h114.7l62.2-98.8L142.3,3H42.8l28.8,45.7h42.1l33.5,53.2L113.6,155H0.1L27.6,200.7z" />
        <path class="st1" d="M22.6,101.8L0,137.7l57.4,0.2l22.7-36.1L17.9,3L22.6,101.8z" />
      </g>
    </g>
  </svg>

</template>

<style type="text/css">
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #FFFFFF;
}

.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #00E5CF;
}
</style>