<script setup="">
import DefaultTransaction from './DefaultTransaction.vue';
import { useI18n } from 'vue-i18n';
import TransactionsApi from '@/api/TransactionsApi';

const transactionsApi = new TransactionsApi()

const props = defineProps({
  transaction: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['delete-transaction'])

const { t } = useI18n()

const deleteTransaction = () => {
  transactionsApi.deleteTransaction('withdraw', props.transaction.id)
    .then(() => emit('delete-transaction'))
}

</script>

<template>
  <div class="withdrawTransaction">
    <DefaultTransaction class="transaction_selection transaction_selection--withdraw" :transaction="transaction">
      <div class="wallet">
        <span>
          Wallet: {{ transaction.data?.wallet }}
        </span>
        <div class="tools" v-if="transaction.status < 10">
          <span v-if="transaction.type === false">
            hello world
          </span>
          <span v-else>
            <router-link :to="`/withdraw/${props.transaction.id}`">{{ t('viewTransaction') }}</router-link>
          </span>
          <span>
            <button @click="deleteTransaction" class="btn">Delete</button>
          </span>
        </div>

      </div>
    </DefaultTransaction>
  </div>

</template>

<style lang="scss" scoped>
.wallet {
  display: flex;
  flex-direction: column;

  span {
    display: block;
    padding: 5px 0;

    a {
      text-decoration: underline;
    }
  }
}

.btn {
  background-color: rgb(246 110 110);
}

.tools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
}
</style>
