<script setup="">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import ApiKeyTransaction from './items/ApiKeyTransaction.vue';
import DefaultTransaction from './items/DefaultTransaction.vue';
import BonusTransaction from './items/BonusTransaction.vue';
import WithdrawTransaction from './items/WithdrawTransaction.vue';
const {t} = useI18n()

const props = defineProps({
  transactions: {
    type: Array,
    required: false,
    default: () => []
  },
  headers: {
    type: Array,
    required: false,
    default: () => [[], []]
  }
})

const emit = defineEmits(['delete-transaction'])

const bonusTransactions = [3, 4, 5, 6, 20, 27, 31]
const withdrawTransactions = [1, 9, 10, 29, 30, 33, 36, 37]
const dexnetApiKeyTransactions = [17, 18, 19]


const transactionComponent = computed(() => (
  transaction => {
    if (bonusTransactions.includes(transaction.type)) return BonusTransaction
    if (withdrawTransactions.includes(transaction.type)) return WithdrawTransaction
    if (dexnetApiKeyTransactions.includes(transaction.type)) return ApiKeyTransaction
    return DefaultTransaction
  }
))

const cellClasses = computed(() => (
  cell => {
    let classes = `w-1/${props.headers[1].length} sm:w-1/${props.headers[0].length}`
    if (!props.headers[1].includes(cell)) classes += ' hidden sm:block'
    return classes
  }
))



</script>

<template>

  <div class="transactions__content">
    <div class="transactions__header">
      <span class="w-1/4" :class="cellClasses(header)" v-for="(header, index) in props.headers[0]" :key="index">{{ t(`headers.${header}`) }}</span>
    </div>
    <div class="transactions__table">
      <component @delete-transaction="emit('delete-transaction')" :is="transactionComponent(transaction)" v-for="(transaction, index) in transactions" :key="index"
        :transaction="transaction" class="mb-2" />
    </div>

  </div>

</template>