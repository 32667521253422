<script setup>
import { ref, computed, useSlots } from 'vue';
import TypeCell from '../ui/TypeCell.vue';
import AmountCell from '../ui/AmountCell.vue';
import TokensCell from '../ui/TokensCell.vue';
import StatusCell from '../ui/StatusCell.vue';
import DefaultField from '../ui/fields/DefaultField.vue';

const props = defineProps({
  transaction: {
    type: Object,
    required: true,
  },
  headers: {
    type: Array,
    required: false,
    default: () => [['type', 'amount', 'tokens', 'status'], ['type', 'status']]
  }
})

const cellComponent = computed(() => (
  header => {
    let cell
    switch (true) {
      case header === 'type':
        cell = TypeCell
        break
      case header === 'amount':
        cell = AmountCell
        break
      case header === 'tokens':
        cell = TokensCell
        break
      case header === 'status':
        cell = StatusCell
        break
    }
    return cell
  }
))

const cellClasses = computed(() => (
  cell => {
    let classes = `w-1/${props.headers[1].length} sm:w-1/${props.headers[0].length}`
    if (!props.headers[1].includes(cell)) classes += ' hidden sm:block'
    return classes
  }
))

const showTransactionAdds = ref(false)

const transactionsExpandFields = computed(() => (
  props.headers[0].filter(x => !props.headers[1].includes(x))
))


</script>


<template>
  <div class="transaction">
    <div class="transaction__data" @click="showTransactionAdds = !showTransactionAdds">
      <component v-for="(cell, index) in headers[0]" :key="index" :is="cellComponent(cell)" :transaction="transaction"
        :class="cellClasses(cell)" class="cell" />
    </div>
    <div class="transaction__expand sm:hidden" v-if="showTransactionAdds">
      <DefaultField class="transaction__expand_field" :title="field" v-for="(field, index) in transactionsExpandFields"
        :key="index" :transaction="transaction">
        <component :is="cellComponent(field)" :transaction="transaction"></component>
      </DefaultField>
    </div>
    <div v-if="showTransactionAdds" class="transaction__additional">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss">
.transaction {
  background-color: #222;
  border-radius: 8px;
  padding-right: .2rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 100%;
  position: relative;
  min-height: 51px;
  font-size: .65rem;
  padding-top: 5px;
  padding-bottom: 5px;

  @media(min-width: 1000px) {
    font-size: 1rem;
  }

  @media(min-width: 1350px) {
    font-size: 1.25rem;
  }
}

.transaction__expand {
  .tokensCell__value {
    flex-direction: row;

    span {
      margin: 0 3px;
    }
  }
}


.transaction__data {
  display: flex;
  align-items: center;
  width: 100%;

}

.transaction__expand {
  padding-left: 3.5rem;
}

.transaction__expand_field {
  display: flex;
}

.transaction__additional {
  padding-left: 3.5rem;
}

.transaction__info {

  position: relative;

  &::before {
    content: '';
    top: -1px;
    position: absolute;
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #3B3B3B;
  }
}

.cell {
  padding: 10px 0;
  text-align: center;
}


.transaction_selection:hover {
  cursor: pointer;



}

.transaction_selection--withdraw:hover {
    -webkit-box-shadow: 0px 0px 8px 2px rgba(255, 110, 110, 0.2);
    -moz-box-shadow: 0px 0px 8px 2px rgba(255, 110, 110, 0.2);
    box-shadow: 0px 0px 8px 2px rgba(255, 110, 110, 0.2);
}

.transaction_selection--bonus:hover {
  -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 246, 99, 0.2);
-moz-box-shadow: 0px 0px 8px 2px rgba(0, 246, 99, 0.2);
box-shadow: 0px 0px 8px 2px rgba(0, 246, 99, 0.2);
}


.transaction_selection--dexnet:hover {
  
-webkit-box-shadow: 0px 0px 8px 2px rgba(0, 255, 240, 0.2);
-moz-box-shadow: 0px 0px 8px 2px rgba(0, 255, 240, 0.2);
box-shadow: 0px 0px 8px 2px rgba(0, 255, 240, 0.2);
}


@media(min-width: 1000px) {
  .cell{
    font-size: .85rem;
  }
}


</style>